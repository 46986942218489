import Observer from './vendor/observer.min';
import { formsPackage } from './functions/form';
import masksPackage from './functions/masks';


export const OBSERVER = Observer.getInstance();

/*
addEventListener('DOMContentLoaded', () => {
  initFormBuilder()
})
*/

export const initFormBuilder = () => {
  const classeContainerFormBuilder = '.dynamicForm';
  formsPackage(classeContainerFormBuilder);
  masksPackage();
  initFileUpload(classeContainerFormBuilder);
};

window.initFormBuilder = initFormBuilder;
